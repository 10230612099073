import React, { useEffect, useState } from "react";
import _ from "lodash";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    zIndex: 1,
    color: "#00CFFF",
  },
  icon: {
    color: "#00CFFF",
    right: 60,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 16,
    justifyContent: "center",
  },
}))(MenuItem);

const BootstrapInput = withStyles((theme) => ({
  root: {
    minWidth: 80,
    "label + &": {
      margin: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 20,
    color: "#00CFFF",
    position: "relative",
    fontSize: 16,
    fontWeight: 700,
    padding: "17.5px 10px",
    "&:focus": {
      borderRadius: 20,
    },
  },
}))(InputBase);

const OutlinedInput = withStyles((theme) => ({
  root: {
    minWidth: 140,
    "label + &": {
      margin: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 5,
    border: `1px solid ${theme.palette.text.primary}`,
    position: "relative",
    backgroundColor: "transparent",
    fontSize: 14,
    fontWeight: 700,
    padding: "10px 10px",
    "&:focus": {
      borderRadius: 4,
      backgroundColor: "transparent",
    },
  },
}))(InputBase);

const StartAdornmentInput = withStyles((theme) => ({
  root: {},
  input: {
    borderRight: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 0,
    backgroundColor: "transparent",
    fontSize: 14,
    fontWeight: 700,
    padding: "10px 10px",
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-outlined": {
      padding: "10px 0px",
    },
  },
}))(InputBase);

export const CustomDropdown = ({ data, onSelect, value, style, type }) => {
  const classes = useStyles();
  let inputType = <BootstrapInput />;
  if (type == "outlined") {
    inputType = <OutlinedInput />;
  }
  if (type == "startAdornment") {
    inputType = <StartAdornmentInput />;
  }

  return (
    <Select
      value={value}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        MenuListProps: { disablePadding: true },
        classes: { paper: classes.menuPaper },
      }}
      style={{ color: "#646464", ...style }}
      input={inputType}
      // classes={{ select: classes.select }}
      onChange={onSelect}
    >
      {data.map((d) => (
        <StyledMenuItem key={d.value} value={d.value} disabled={d.disabled}>
          {d.label}
        </StyledMenuItem>
      ))}
    </Select>
  );
};

export default CustomDropdown;
