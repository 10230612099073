import React, { Component } from "react";
import _ from "lodash";
import CanvasJSReact from "./canvasjs.react";
import BigNumber from "bignumber.js";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
export default function DashboardPieChart({ data, colors, total }) {
  const options = {
    animationEnabled: true,
    subtitles: [
      {
        text: `$${new BigNumber(total).toFormat(1)}`,
        verticalAlign: "center",
        fontSize: 26,
        fontColor: "#0B308E",
        dockInsidePlotArea: true,
      },
    ],

    data: [
      {
        type: "doughnut",
        indexLabelFontSize: 19,
        indexLabelFontColor: "#636B7D",
        showInLegend: false,
        indexLabel: "{y}",
        yValueFormatString: "#,###'%'",
        dataPoints: _.reduce(
          data,
          (result, value, key) => {
            result.push({
              name: "Class " + key,
              y: value,
              color: colors[key],
            });
            return result;
          },
          []
        ),
      },
    ],
  };
  const containerProps = {
    width: "100%",
    height: "250px",
    margin: "auto",
  };
  return (
    <div>
      <CanvasJSChart options={options} containerProps={containerProps} />
    </div>
  );
}
