import axios from "axios";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PRICE_BEGIN,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
} from './constants';
import { uniswapV2PairABI, liquidityBootstrappingPoolABI, balancerVaultABI, contracts } from 'features/configure';
import { convertAmountFromRawNumber } from "../helpers/bignumber";

export function fetchPrice() {
  return dispatch => {
    dispatch({
      type: FETCH_PRICE_BEGIN
    });

    const promise = new Promise(async(resolve, reject) => {

      Promise.all([

        axios.get(`https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=max`),
        axios.get(`https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=max`),
        axios.get(`https://api.coingecko.com/api/v3/coins/binancecoin/market_chart?vs_currency=usd&days=max`),
        axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,binancecoin&vs_currencies=usd&include_24hr_change=true`),

        
      ]).then(data => {
        dispatch({
          type: FETCH_PRICE_SUCCESS,
          data
        });
        resolve(data);
      })
        .catch(error => {
          dispatch({
            type: FETCH_PRICE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceData, chart } = useSelector(state => ({
    fetchPricePending: state.price.fetchPricePending,
    priceData: state.price.priceData,
    chart: state.price.chart,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceData,
    chart
  };
}

export function reducer(state = { fetchPricePending: false, priceData: {}, lpData: {}, chart:[] }, action) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_SUCCESS:

     
      let btc = action.data[0].data.prices
      let eth = action.data[1].data.prices
      let bnb = action.data[2].data.prices
      let btcToday = action.data[3].data.bitcoin
      let ethToday = action.data[3].data.ethereum
      let bnbToday = action.data[3].data.binancecoin
      const priceData = {btc, eth, bnb,btcToday,ethToday,bnbToday}
      return {
        ...state,
        priceData: priceData,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    default:
      return state;
  }
}
