import {
  container,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

const drawerWidth = "14rem";
const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 12,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    marginRight: "auto",
    marginLeft: "auto",
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  sideBarDrawerPaper: {
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: theme.palette.background.menu,
    borderRight: `1px solid ${theme.palette.background.grey}`,
    overflow: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sideBarDrawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: `1px solid ${theme.palette.background.grey}`,
  },
  appBar2: {
    display: "flex",
    border: "0",
    borderRadius: "3px",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: grayColor[15],
    width: "100%",
    backgroundColor: whiteColor,
    boxShadow:
      "0 4px 18px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 7px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
  },
  absolute: {
    position: "absolute",
    top: "auto",
  },
  fixed: {
    position: "fixed",
  },
  container: {
    ...container,
    minHeight: "60px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
  },
  title: {
    letterSpacing: "unset",
    "&,& a": {
      minWidth: "unset",
      lineHeight: "28px",
      fontSize: "21px",
      fontWeight: "700",
      borderRadius: "3px",
      textTransform: "none",
      whiteSpace: "nowrap",
      color: "#F8F8F8",
      alignItems: "flex-end",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent",
      },
    },
    "& a": {
      marginLeft: "8px",
    },
  },
  appResponsive: {
    margin: "0 10px",
    marginTop: "40px",
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.46)",
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.46)",
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.46)",
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.46)",
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.46)",
  },
  rose: {
    backgroundColor: roseColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.46)",
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "25px",
    color: whiteColor,
  },
  dark: {
    color: whiteColor,
    backgroundColor: theme.palette.background.menu,
    boxShadow: "0px 0px",
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: grayColor[15],
    backgroundColor: whiteColor + " !important",
    boxShadow:
      "0 4px 18px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 7px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.15)",
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    backgroundColor: theme.palette.background.menu,
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: 260,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "auto",
    left: "0",
    visibility: "visible",
    overflowY: "auto",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition,
  },
  hidden: {
    width: "100%",
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto",
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
  },
  closeButtonDrawer: {
    position: "absolute",
    right: "8px",
    top: "9px",
    zIndex: "1",
  },
  tabs: {
    width: "60xh",
    display: "flex",
    justifyContent: "space-between",
    overflow: "visible",
  },
  logo: {
    alignSelf: "center",
  },
  link: {
    margin: "0 1rem",
    fontSize: "1rem",
    fontWeight: 400,
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    "& span": {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    color: theme.palette.text.primary,
    marginRight: "0.5rem",
    minWidth: "24px",
    textAlign: "end",
  },
  iconButton: {
    color: theme.palette.text.primary,
  },
  menuLink: {
    fontWeight: 700,
    "&.Mui-selected": {
      color: `${theme.palette.text.green}`,
    },
    "&:hover": {
      color: `${theme.palette.text.green}`,
    },
    "&:focus": {
      color: `${theme.palette.text.green}`,
    },
  },
  btnBoost: {
    marginLeft: "10px",
    marginRight: "5px",
    padding: "3px 4px",
    border: "solid 2px #5a8f69",
    borderRadius: "4px",
    "& img": {
      verticalAlign: "middle",
      height: "15px",
    },
    "&:hover": {
      backgroundColor: "#5a8f69",
    },
    "&:hover img": {
      filter:
        "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(1000%) contrast(1000%)",
    },
  },
  header: {
    color: `${theme.palette.text.minor}`,
    fontSize: "20px",
  },
  headerSection: {
    backgroundColor: `${theme.palette.background.menu}`,
    fontWeight: 600,
    padding: "15px",
  },
  listItems: {
    color: `#C7C7C7`,
    borderRadius: 5,
    fontSize: 14,
    height: 48,
    fontWeight: 500,
    margin: "3px 10px",
    width: "92%",
    "&.Mui-selected": {
      color: `${theme.palette.text.green}`,
    },
    "&:hover": {
      color: `${theme.palette.text.green}`,
      backgroundColor: `#F0F7FF`,
      "& > img:first-child": {},
    },
    "&:focus": {
      color: `${theme.palette.text.green}`,
      backgroundColor: `#F0F7FF`,
      "& > img:first-child": {},
    },
    "& > img:first-child": {
      marginRight: 18,
      fontSize: 14,
    },
  },
  itemLink: {
    color: `${theme.palette.text.primary}`,

    fontWeight: 800,
    "&:hover": {
      color: `${theme.palette.text.green}`,
    },
    "&:focus": {
      color: `${theme.palette.text.green}`,
    },
  },
});

export default styles;
