import React, { Component } from "react";
import _ from "lodash";
import CanvasJSReact from "./canvasjs.react";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class DashboardChart extends Component {
  render() {
    const options = {
      animationEnabled: true,
      zoomEnabled: true,
      axisY: {
        // title: "$",
      },
      toolTip: {
        borderThickness: 0,
        cornerRadius: 12,
      },
      data: [
        {
          type: "splineArea",
          color: "#648CF2",
          toolTipContent: `<div style='\"'padding: 10px;'\"'><h2>\${y}</h2><span style='\"'color:rgba(60, 60, 67, 0.6);'\"'>{x}</span></div>`,
          markerSize: 3,
          fillOpacity: 0.1,
          lineThickness: 4,
          dataPoints: this.props.data,
        },
      ],
    };
    const containerProps = {
      width: "90%",
      height: "280px",
      margin: "auto",
    };
    return (
      <div>
        <CanvasJSChart options={options} containerProps={containerProps} />
      </div>
    );
  }
}
export default DashboardChart;
