import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_FUND_BEGIN,
  FETCH_FUND_SUCCESS,
  FETCH_FUND_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import { apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import axios from "axios";
export function fetchFund({ type }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_FUND_BEGIN,
      });
      let result = await axios.get(apiUrl + "stats?class=" + type);
      let output = result.data;
      console.log(output);
      dispatch({
        type: FETCH_FUND_SUCCESS,
        data: output,
      });
    } catch (err) {
      dispatch({
        type: FETCH_FUND_FAILURE,
      });
      console.log(err);
    }
  };
}

export function useFetchFund() {
  const dispatch = useDispatch();

  const { fundData, fetchFundPending } = useSelector(
    (state) => ({
      fundData: state.home.fundData,
      fetchFundPending: state.home.fetchFundPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchFund(data));
    },
    [dispatch]
  );

  return {
    fundData,
    fetchFund: boundAction,
    fetchFundPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_FUND_BEGIN:
      return {
        ...state,
        fetchFundPending: true,
      };

    case FETCH_FUND_SUCCESS:
      return {
        ...state,
        fundData: action.data,
        fetchFundPending: false,
      };

    case FETCH_FUND_FAILURE:
      return {
        ...state,
        fetchFundPending: false,
      };

    default:
      return state;
  }
}
