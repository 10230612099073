import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import BigNumber from "bignumber.js";
import Pagination from "@material-ui/lab/Pagination";
import DashboardChart from "./components/DashboardChart";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "components/CustomTabs/Tabs.js";
import { useFetchDashboard } from "./redux/fetchDashboard";
import { useAuth } from "features/home/redux/hooks";
const Fund = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [tabValue, setTabValue] = React.useState(0);
  const [page, setPage] = React.useState(1);
  // const { fetchFund, fetchFundPending, fundData } = useFetchFund();
  const { fetchDashboard, fetchDashboardPending, data } = useFetchDashboard();
  const { auth } = useAuth();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (auth) {
      fetchDashboard({ token: auth });
      const id = setInterval(() => {
        fetchDashboard({ token: auth });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [tabValue, auth]);
  const mapping = { 0: "A", 1: "B", 2: "C" };
  const NAV = {
    A: new BigNumber(_.get(data, "NAV.classA", "0")),
    B: new BigNumber(_.get(data, "NAV.classB", "0")),
    C: new BigNumber(_.get(data, "NAV.classC", "0")),
  };

  const renderCard = (img, header, num) => {
    return (
      <div
        className="card"
        style={{
          background: "white",
          overflow: "hidden",
          margin: 0,
          height: 200,
          position: "relative",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <img
              src={require("assets/img/" + img).default}
              style={{ height: 40, width: 40, objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              className="cardSubHeader"
              style={{ margin: "20px 0", fontSize: 14 }}
            >
              {header}
            </div>
            <div>
              <span
                className={"cardLgValue"}
                style={{ position: "absolute", bottom: 30 }}
              >
                {num}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
  const renderFundContent = (coin, type, inceptionDate, desc) => {
    const net = `$ ${new BigNumber(
      _.get(NAV, `${mapping[tabValue]}`, 0)
    ).toFormat(0)}
        `;
    const monthlyData =
      data &&
      _.map(_.get(data, `sharePrices.class${mapping[tabValue]}`, []), (d) => {
        if (!d) return {};
        return { x: new Date(d.timestamp * 1000), y: d.sharePrice };
      });

    return (
      <div className="card">
        <div className="cardHeader">Fund Overview</div>

        <Grid container spacing={2} style={{ overflow: "hidden" }}>
          <Grid
            item
            xs={12}
            md={4}
            style={{ color: "#213E53", lineHeight: "24px" }}
          >
            {desc}
          </Grid>
          <Grid item xs={12} md={2}>
            {renderCard("coin.png", "Base Currency for Fund", coin)}
          </Grid>
          <Grid item xs={12} md={2}>
            {renderCard("asset.png", "Total Net Assets(USDT)", net)}
          </Grid>
          <Grid item xs={12} md={2}>
            {renderCard("calandar.png", "Fund inception Date", inceptionDate)}
          </Grid>
          <Grid item xs={12} md={2}>
            {renderCard("currency.png", "Investment Style", type)}
          </Grid>
        </Grid>
        <div className="cardHeader" style={{ marginTop: 30 }}>
          Share Price
        </div>
        <div style={{ height: 400 }}>
          <DashboardChart data={monthlyData} />
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="title">Fund</div>
      <div className="subTitle">
        Get summary of your online transactions here.
      </div>
      <Tabs
        tabIndex={tabValue}
        handleChange={handleTabChange}
        tabs={[
          {
            label: "Class A",
            content: renderFundContent("USDT", "Stable Fund","Apr, 2019","Generate consistent & relatively stable return while maintaining market risk neutral"),
          },
          {
            label: "Class B",
            content: renderFundContent("BTC", "Token Fund","Feb, 2021","Create absolute return under all market environments via using a variety financial tools, such as derivatives, short selling, and leverage"),
          },
          {
            label: "Class C",
            content: renderFundContent("ALT", "Token Fund","Jan, 2018","Provide long-term maximum return outperforming overall Crypto market for investor"),
          },
        ]}
      />
      
    </div>
  );
};

export default Fund;
