import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import Social from "features/home/components/Social";
import DashboardChart from "./components/DashboardChart";
import { default as DashboardPieChart } from "./components/DashboardPieChart";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useFetchDashboard } from "./redux/fetchDashboard";
import _ from "lodash";
import { useAuth } from "features/home/redux/hooks";
import Tabs from "components/CustomTabs/Tabs.js";
import { IconButton } from "@material-ui/core";
const Intro = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [tabValue, setTabValue] = React.useState(0);
  const { fetchDashboard, fetchDashboardPending, data } = useFetchDashboard();
  const { auth } = useAuth();
  useEffect(() => {
    if (auth) {
      fetchDashboard({ token: auth });
      const id = setInterval(() => {
        fetchDashboard({ token: auth });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [auth]);
  const colors = { A: "#0B308E", B: "rgb(243, 157, 26)", C: "#E2507A" };
  const NAV = {
    A: new BigNumber(_.get(data, "NAV.classA", "0")),
    B: new BigNumber(_.get(data, "NAV.classB", "0")),
    C: new BigNumber(_.get(data, "NAV.classC", "0")),
  };
  const totalNAV = _.get(data, "totalNAV", 1);
  const initNAV = {
    A: new BigNumber(_.get(data, "initNAV.classA", 0)),
    B: new BigNumber(_.get(data, "initNAV.classB", 0)),
    C: new BigNumber(_.get(data, "initNAV.classC", 0)),
  };
  const totalInitNAV =
    parseFloat(initNAV["A"]) +
    parseFloat(initNAV["B"]) +
    parseFloat(initNAV["C"]);
  const NAVPersent = {
    A: (NAV["A"] * 100) / totalNAV,
    B: (NAV["B"] * 100) / totalNAV,
    C: (NAV["C"] * 100) / totalNAV,
  };
  const initNAVPersent = {
    A: (initNAV["A"] * 100) / totalNAV,
    B: (initNAV["B"] * 100) / totalNAV,
    C: (initNAV["C"] * 100) / totalNAV,
  };
  const mapping = { 1: "A", 2: "B", 3: "C" };

  const currentBalance = totalNAV;
  const initBalance = totalInitNAV;
  const varity = (currentBalance - initBalance) / initBalance;
  const renderNAVNumber = (type) => {
    const growth = NAV[type]
      .minus(initNAV[type])
      .multipliedBy(100)
      .dividedBy(initNAV[type])
      .toFormat(1);
    return (
      <div style={{ margin: "40px 10px" }}>
        <div className="cardSubHeader">Class {type} - Current</div>
        <div
          style={{
            backgroundColor: colors[type],
            height: 5,
            borderRadius: 5,
            margin: "5px 0",
          }}
        ></div>
        <div className="betweenRow">
          <span
            className={growth >= 0 ? "cardGreenValue" : "cardRedValue"}
            style={{ fontSize: 15, opacity: 0.7 }}
          >
            {growth >= 0 ? "+" : ""}
            {isNaN(growth) ? "-" : growth} %
          </span>
          <span className="cardValue">$ {NAV[type].toFormat(0)}</span>
        </div>
      </div>
    );
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const monthlyData = _.map(
    _.get(
      data,
      tabValue == 0 ? "monthly.total" : `monthly.class${mapping[tabValue]}`,
      []
    ),
    (d) => {
      if (!d) return {};
      return { x: new Date(d.timestamp * 1000), y: d.totalNAV };
    }
  );
  const renderFundChart = () => {
    if (!data) return;

    return <DashboardChart data={monthlyData} />;
  };

  const renderFundOverview = () => {
    if (!data) return;
    let currentTotal = totalNAV;
    let inception = totalNAV - totalInitNAV;
    let fundVarity = varity;
    let fundMonthly =
      monthlyData.length > 2 ? monthlyData[0].y - monthlyData[1].y : "-";
    let fundMonthlyGrowth =
      monthlyData.length > 2 ? fundMonthly / monthlyData[1].y : "-";
    if (tabValue > 0) {
      const fundType = mapping[tabValue];
      currentTotal = NAV[fundType];
      inception = NAV[fundType] - initNAV[fundType];
      fundVarity = inception / initNAV[fundType];
    }
    return (
      <div className="card">
        <div className="cardHeader">Fund Overview</div>
        <div className="cardSubheader">Net Asset Value</div>
        <Grid container>
          <Grid item xs={12} sm={10}>
            {renderFundChart()}
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className="card" style={{ textAlign: "right" }}>
              <div className="cardContent">Monthly</div>
              <div>
                <span
                  className={
                    fundMonthly >= 0 ? "cardGreenValue" : "cardRedValue"
                  }
                >
                  $ {new BigNumber(fundMonthly).toFormat(0)}
                  {" / "}
                  {parseFloat(
                    isNaN(fundMonthlyGrowth) ? 0 : fundMonthlyGrowth * 100
                  ).toFixed(1)}
                  %
                </span>
              </div>
              <hr />
              <div className="cardContent">Quaterly</div>
              <div className="cardGreenValue">-</div>
              <hr />
              <div className="cardContent">Since Inception</div>
              <div>
                <span
                  className={
                    fundVarity >= 0 ? "cardGreenValue" : "cardRedValue"
                  }
                >
                  $ {new BigNumber(inception).toFormat(0)}
                  {" / "}
                  {parseFloat(fundVarity * 100).toFixed(1)}%
                </span>
              </div>
              <hr />

              <div className="cardContent">Current</div>
              <div className="cardGreenValue" style={{ color: "#0B308E" }}>
                $ {new BigNumber(currentTotal).toFormat(0)}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderInitNAVNumber = (type) => {
    return (
      <div style={{ margin: "20px 10px" }}>
        <div className="cardSubHeader">Class {type} -Initial</div>
        <div
          style={{
            backgroundColor: colors[type],
            height: 4,
            borderRadius: 5,
            margin: "5px 0",
          }}
        ></div>
        <div className="cardValue" style={{ textAlign: "right" }}>
          $ {initNAV[type].toFormat(0)}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="title">Asset Infomation</div>
      <div className="subTitle"></div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Tabs
            tabIndex={tabValue}
            handleChange={handleTabChange}
            tabs={[
              {
                label: "Overview",
                content: renderFundOverview(),
              },
              {
                label: "Class A",
                content: renderFundOverview(),
              },
              {
                label: "Class B",
                content: renderFundOverview(),
              },
              {
                label: "Class C",
                content: renderFundOverview(),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className="card" style={{ minHeight: 350 }}>
            <div className="cardHeader">Current Stragety Chart</div>
            <Grid container>
              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    {renderNAVNumber("A")}
                    {renderInitNAVNumber("A")}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {renderNAVNumber("B")}
                    {renderInitNAVNumber("B")}
                  </Grid>{" "}
                  <Grid item xs={12} md={4}>
                    {renderNAVNumber("C")}
                    {renderInitNAVNumber("C")}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} style={{ position: "relative" }}>
                {data && (
                  <DashboardPieChart
                    data={NAVPersent}
                    colors={colors}
                    total={totalNAV}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <IconButton
          onClick={() => (window.location.href = "mailto:james@pwmc.com")}
        >
          <img src={require("assets/img/advisor.svg").default} />
        </IconButton>
      </div>
    </div>
  );
};

export default Intro;
