import React, { Component } from 'react';
import _ from "lodash";
import CanvasJSReact from './canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;
class PriceChart extends Component {
  constructor(props) {
    super(props);
    this.state = { dataPoints: _.reduce(props.priceData,(sum,i)=>{
      sum.push({
        x: new Date(i[0]),
        y: Number(i[1])
      });
      return sum
    },[]), isLoaded: false };
  }

  render() {
    
    const options = {
      theme: "light2",
      charts: [{
        axisX: {
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
            valueFormatString: "MMM DD YYYY"
          }
        },
        axisY: {
          title: "Price",
          prefix: "$",
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
            valueFormatString: "$#,###.##"
          }
        },
        toolTip: {
          shared: true
        },
        data: [{
          name: "Price",
          type: "splineArea",     
          color: "#648CF2",
          yValueFormatString: "$#,###.##",
          xValueFormatString: "MMM DD YYYY",
          dataPoints : this.state.dataPoints,
          markerSize: 3,
          fillOpacity: 0.1,
          lineThickness: 4,
        }]
      }],
      navigator: {
        enabled: false
      }
    };
    const containerProps = {
      width: "100%",
      height: "470px",
      margin: "auto"
    };
    return (
      <div> 
        <div>
          {
       
            <CanvasJSStockChart containerProps={containerProps} options = {options}
            />
          }
        </div>
      </div>
    );
  }
}
export default PriceChart;