import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import Button from "components/CustomButtons/Button";
import { useAuth } from "features/home/redux/hooks";
import { Redirect, useHistory } from "react-router-dom";
import _ from "lodash";
const Login = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [email, setEmail] = React.useState("");
  const [verifyCode, setVerifyCode] = React.useState("");
  // const [password, setPassword] = React.useState("");
  const [fa, setFa] = React.useState("");
  const [isFirst, setIsFirst] = React.useState(true);
  const {
    login,
    user,
    auth,
    connectWalletPending,
    request,
    verifyData,
    error,
  } = useAuth();
  const history = useHistory();
  useEffect(async () => {
    if (verifyData && !_.get(verifyData, "qrcodeImg")) {
      setIsFirst(false);
    }
  }, [verifyData]);
  if (auth) return <Redirect to="/" />;
  if (isFirst) {
    return (
      <div
        style={{
          maxWidth: 900,
          marginTop: "30vh",
        }}
      >
        <div
          className="card"
          style={{ textAlign: "center", padding: 30, margin: "0 auto" }}
        >
          <h3>Login</h3>
          <div style={{ maxWidth: 500, margin: "30px auto" }}>
            <Grid container alignItems="center" style={{ textAlign: "left" }}>
              <Grid item xs={3}>
                Email
              </Grid>
              <Grid item xs={9}>
                <CustomOutlinedInput
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ color: "red" }}>{error}</div>
          {_.get(verifyData, "qrcodeImg") ? (
            <div>
              <img
                src={_.get(verifyData, "qrcodeImg")}
                style={{ height: 150 }}
              />
              <div>
                <h4>Its will only generate once!</h4>
              </div>
              <Button
                color="secondary"
                disabled={connectWalletPending}
                onClick={async () => {
                  setIsFirst(false);
                }}
              >
                Next
              </Button>
            </div>
          ) : (
            <Button
              color="secondary"
              disabled={connectWalletPending}
              onClick={async () => {
                if (!email) return;
                await request({ email });
                if (!verifyData) return;
              }}
            >
              Send Verify
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        maxWidth: 900,
        marginTop: "30vh",
      }}
    >
      <div
        className="card"
        style={{ textAlign: "center", padding: 30, margin: "0 auto" }}
      >
        <h3>Login</h3>
        <div style={{ maxWidth: 500, margin: "30px auto" }}>
          <Grid container alignItems="center" style={{ textAlign: "left" }}>
            <Grid item xs={3}>
              Email
            </Grid>
            <Grid item xs={9}>
              <CustomOutlinedInput
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              Email Verify Code
            </Grid>
            <Grid item xs={9}>
              <CustomOutlinedInput
                value={verifyCode}
                placeholder="Verify Code"
                onChange={(e) => setVerifyCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              2FA
            </Grid>
            <Grid item xs={9}>
              <CustomOutlinedInput
                value={fa}
                placeholder="2FA"
                onChange={(e) => setFa(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ color: "red" }}>{error}</div>
        <Button
          color="secondary"
          disabled={connectWalletPending}
          onClick={async () => {
            if (!email || !fa || !verifyCode) return;
            await login({ email, fa, verifyCode });
            history.push("/");
          }}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default Login;
