import { Intro, PricePage, Fund, Login, Internal } from ".";
export default {
  path: "",
  childRoutes: [
    { path: "", component: Intro, isIndex: true },
    { path: "login", component: Login, isIndex: true },
    { path: "price", component: PricePage, isIndex: false },
    { path: "fund", component: Fund, isIndex: false },
  ],
};
