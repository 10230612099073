import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_TRANS_BEGIN,
  FETCH_TRANS_SUCCESS,
  FETCH_TRANS_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import { apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import axios from "axios";
export function fetchTransactions({ type, page }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_TRANS_BEGIN,
      });
      let result = await axios.get(
        apiUrl + "transactions?page=" + page + "&class=" + type
      );
      let output = {
        data: result.data,
        total: parseInt(result.headers["x-total-count"] / 20 + 1),
      };
      console.log(output);
      dispatch({
        type: FETCH_TRANS_SUCCESS,
        data: output,
      });
    } catch (err) {
      dispatch({
        type: FETCH_TRANS_FAILURE,
      });
      console.log(err);
    }
  };
}

export function useFetchTransactions() {
  const dispatch = useDispatch();

  const { transactionsData, fetchTransactionsPending, totalPage } = useSelector(
    (state) => ({
      transactionsData: state.home.transactionsData,
      fetchTransactionsPending: state.home.fetchTransactionsPending,
      totalPage: state.home.totalPage,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchTransactions(data));
    },
    [dispatch]
  );

  return {
    transactionsData,
    totalPage,
    fetchTransactions: boundAction,
    fetchTransactionsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_TRANS_BEGIN:
      return {
        ...state,
        fetchTransactionsPending: true,
      };

    case FETCH_TRANS_SUCCESS:
      return {
        ...state,
        transactionsData: action.data.data,
        totalPage: action.data.total,
        fetchTransactionsPending: false,
      };

    case FETCH_TRANS_FAILURE:
      return {
        ...state,
        fetchTransactionsPending: false,
      };

    default:
      return state;
  }
}
