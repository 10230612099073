import React, { useEffect, useState } from "react";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import UserInfo from "./components/UserInfo";
import { useTranslation } from "react-i18next";
import { Notifier } from "features/common";
import appStyle from "./jss/appStyle.js";
import createAppTheme from "./jss/appTheme";
import Header from "components/Header/Header";
import { useAuth } from "features/home/redux/hooks";
import { Redirect, useHistory } from "react-router-dom";
import Login from "./Login";
const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = createAppTheme(true);
  const { user, auth } = useAuth();
  const history = useHistory();
  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>
        <div
          style={{
            minHeight: "100vh",
            backgroundColor: "white",
          }}
        >
          <div style={{ margin: "0 auto" }}>
            <Header />
            <main className={classes.container}>
              <UserInfo />
              {auth ? children : <Login />}
            </main>
            {/* <Notifier /> */}
          </div>
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}
