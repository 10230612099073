import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_INTERNAL_BEGIN,
  FETCH_INTERNAL_SUCCESS,
  FETCH_INTERNAL_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import { apiUrl } from "../../configure";
import BigNumber from "bignumber.js";
import axios from "axios";
export function fetchInternal({ type }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_INTERNAL_BEGIN,
      });
      let result = await axios.get(apiUrl + "stats/class");
      let output = result.data;
      console.log(output);
      dispatch({
        type: FETCH_INTERNAL_SUCCESS,
        data: output,
      });
    } catch (err) {
      dispatch({
        type: FETCH_INTERNAL_FAILURE,
      });
      console.log(err);
    }
  };
}

export function useFetchInternal() {
  const dispatch = useDispatch();

  const { internalData, fetchInternalPending } = useSelector(
    (state) => ({
      internalData: state.home.internalData,
      fetchInternalDataPending: state.home.fetchInternalDataPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchInternal(data));
    },
    [dispatch]
  );

  return {
    internalData,
    fetchInternal: boundAction,
    fetchInternalPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_INTERNAL_BEGIN:
      return {
        ...state,
        fetchInternalPending: true,
      };

    case FETCH_INTERNAL_SUCCESS:
      return {
        ...state,
        internalData: action.data,
        fetchInternalPending: false,
      };

    case FETCH_INTERNAL_FAILURE:
      return {
        ...state,
        fetchInternalPending: false,
      };

    default:
      return state;
  }
}
