export const HOME_CONNECT_WALLET_BEGIN = "HOME_CONNECT_WALLET_BEGIN";
export const HOME_CONNECT_WALLET_SUCCESS = "HOME_CONNECT_WALLET_SUCCESS";
export const HOME_CONNECT_WALLET_FAILURE = "HOME_CONNECT_WALLET_FAILURE";
export const HOME_ACCOUNTS_CHANGED = "HOME_ACCOUNTS_CHANGED";
export const HOME_NETWORK_CHANGED = "HOME_NETWORK_CHANGED";

export const HOME_REQUEST_LOGIN_BEGIN = "HOME_REQUEST_LOGIN_BEGIN";
export const HOME_REQUEST_LOGIN_SUCCESS = "HOME_REQUEST_LOGIN_SUCCESS";
export const HOME_REQUEST_LOGIN_FAILURE = "HOME_REQUEST_LOGIN_FAILURE";

export const HOME_DISCONNECT_WALLET_BEGIN = "HOME_DISCONNECT_WALLET_BEGIN";
export const HOME_DISCONNECT_WALLET_SUCCESS = "HOME_DISCONNECT_WALLET_SUCCESS";
export const HOME_DISCONNECT_WALLET_FAILURE = "HOME_DISCONNECT_WALLET_FAILURE";

export const FETCH_MINT_BEGIN = "FETCH_MINT_BEGIN";
export const FETCH_MINT_SUCCESS = "FETCH_MINT_SUCCESS";
export const FETCH_MINT_FAILURE = "FETCH_MINT_FAILURE";

export const FETCH_TVL_BEGIN = "FETCH_TVL_BEGIN";
export const FETCH_TVL_SUCCESS = "FETCH_TVL_SUCCESS";
export const FETCH_TVL_FAILURE = "FETCH_TVL_FAILURE";

export const FETCH_FUND_BEGIN = "FETCH_FUND_BEGIN";
export const FETCH_FUND_SUCCESS = "FETCH_FUND_SUCCESS";
export const FETCH_FUND_FAILURE = "FETCH_FUND_FAILURE";

export const FETCH_TRANS_BEGIN = "FETCH_TRANS_BEGIN";
export const FETCH_TRANS_SUCCESS = "FETCH_TRANS_SUCCESS";
export const FETCH_TRANS_FAILURE = "FETCH_TRANS_FAILURE";

export const FETCH_INTERNAL_BEGIN = "FETCH_INTERNAL_BEGIN";
export const FETCH_INTERNAL_SUCCESS = "FETCH_INTERNAL_SUCCESS";
export const FETCH_INTERNAL_FAILURE = "FETCH_INTERNAL_FAILURE";

export const UPDATE_INTERNAL_BEGIN = "UPDATE_INTERNAL_BEGIN";
export const UPDATE_INTERNAL_SUCCESS = "UPDATE_INTERNAL_SUCCESS";
export const UPDATE_INTERNAL_FAILURE = "UPDATE_INTERNAL_FAILURE";
