import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
const useStyles = makeStyles((theme) => ({
  field: {
    padding: 15,
    paddingTop: 0,
    borderRadius: 15,
    color: "white",
    backgroundColor: "transparent",
    overflow: "visible",
    textAlign: "center",
    position: "relative",
    // boxShadow: "10px 10px 10px 1px rgba(0, 0, 0, 0.5)",
  },
  inputRoot: {
    color: "white",
  },
  showDetail: {
    marginTop: 5,
    marginBottom: 10,
    justifyContent: "space-around",
    width: "100%",
    color: "#5C5C5C",
    borderBottom: `1px solid #C2C2C2`,
    backgroundColor: "transparent",
    fontWeight: 600,
    "& .MuiInputBase-root": {
      color: "#5C5C5C",
      fontSize: 17,
    },
    "& fieldset": { color: "#5C5C5C" },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "transparent",
      color: "#5C5C5C",
      border: `none`,
      "& fieldset": {
        border: `none`,
      },
    },
    outline: "none",
  },
  center: {
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  option: {
    marginTop: 8,
    "& .MuiInputBase-root": {
      color: "#5C5C5C",
    },
  },
  sm: {
    "& .MuiInputBase-root": {
      fontSize: 13,
    },
  },
  hovered: {
    "& fieldset": {},
  },
  focused: {
    "& fieldset": {},
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default function CustomOutlinedInput(props) {
  const {
    classes,
    disabled,
    value,
    placeholder,
    center,
    option,
    startAdornment,
    endAdornment,
    sm,
    number,
    max,
    multiline = false,
  } = props;
  const commonStyle = useStyles();
  const commonClasses = {
    root: `${commonStyle.showDetail} ${center && commonStyle.center} ${
      sm && commonStyle.sm
    } ${option && commonStyle.option}`,
    disabled: commonStyle.disabled,
  };

  if (disabled) {
    return (
      <div style={{ textAlign: center ? "center" : "left", padding: 10 }}>
        <div
          style={{
            fontSize: sm ? 14 : 18,
            paddingLeft: 5,
            fontWeight: 500,
            color: option ? "#648AB7" : "#5C5C5C",
          }}
        >
          {value}
        </div>
      </div>
    );
  }
  let inputProps = {
    startAdornment: props.startAdornment,
    disableUnderline: true,
    endAdornment: (
      <InputAdornment position="end" className={commonStyle.inputAdornment}>
        {endAdornment}
      </InputAdornment>
    ),
  };
  if (startAdornment) inputProps["startAdornment"] = startAdornment;
  if (number) inputProps["inputComponent"] = NumberFormatCustom;
  return (
    <div>
      <div className={commonStyle.field} style={{ height: 50 }}>
        <TextField
          {...props}
          placeholder={placeholder || "0.0"}
          rows={1}
          multiline={multiline}
          classes={Object.assign({}, commonClasses, classes)}
          color="secondary"
          InputProps={inputProps}
        />
      </div>
    </div>
  );
}

CustomOutlinedInput.defaultProps = {
  fullWidth: true,
};
