import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { useAuth } from "features/home/redux/hooks";
import Button from "components/CustomButtons/Button";
import { useHistory } from "react-router-dom";
import _ from "lodash";
const UserInfo = ({}) => {
  const { user, auth, logout } = useAuth();
  const history = useHistory();
  if (auth)
    return (
      <span style={{ float: "right", marginTop: 10 }}>
        <IconButton>
          <img src={require("assets/img/user.png").default} className="icon" />
          <span style={{ marginLeft: 8 }}>
            <div style={{ color: "#404040", fontSize: 14, textAlign: "left" }}>
              {_.get(user, "email")}
            </div>
            {/* <div className="label">Admin account</div> */}
          </span>
        </IconButton>
        <Button
          onClick={() => logout()}
          style={{ color: "#404040" }}
          color="secondary"
        >
          Logout
        </Button>
      </span>
    );
  return (
    <span style={{ float: "right", marginTop: 10 }}>
      <Button color="secondary" onClick={() => history.push("/login")}>
        Login
      </Button>
    </span>
  );
};

export default UserInfo;
